var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accountAndService"},[_c('title-center',{attrs:{"name":"监管账户","titleTips":_vm.titleTipsSupervise()}}),_c('base-form',{ref:"superviseForm",staticClass:"formData",attrs:{"componentList":_vm.superviseForm,"formAttrs":{
      model: _vm.accountData,
      labelWidth: '100px',
      disabled: _vm.isDetail
    },"showBtns":false},on:{"add":_vm.add}}),_c('title-center',{attrs:{"name":"放款账户","rules":_vm.dictType!=='4'}}),_vm._l((_vm.finRepayBankAccountInfoDTOs),function(item,index){return _c('div',{key:index},[_c('base-form',{ref:"settlementAccountForm",refInFor:true,staticClass:"formData",attrs:{"componentList":_vm.settlementAccount(index),"formAttrs":{
      model: item,
      labelWidth: '140px',
      disabled: _vm.isDetail
    },"showBtns":false}})],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}]},[_c('title-center',{attrs:{"name":"保理服务信息","titleTips":_vm.titleTipsFactoringRelatedForm()}}),_c('base-form',{ref:"factoringForm",staticClass:"formData",attrs:{"componentList":_vm.factoringRelatedForm,"formAttrs":{
        model: _vm.newServiceData,
        labelWidth: '140px',
        disabled: _vm.isDetail
      },"showBtns":false}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- 监管账户和保理服务信息 -->
  <div class="accountAndService">
    <title-center name="监管账户" :titleTips='titleTipsSupervise()'></title-center>
    <base-form
      :componentList="superviseForm"
      :formAttrs="{
        model: accountData,
        labelWidth: '100px',
        disabled: isDetail
      }"
      @add='add'
      :showBtns="false"
      class="formData"
      ref="superviseForm"
    >
    </base-form>
    <!-- 财富资金显示 取消限制 所有产品均显示 2022/6/20  岳成材 -->
    <!-- 中小企，放款账户取消校验，0916需求反馈 -->
    <title-center name="放款账户" :rules="dictType!=='4'"></title-center>
    <div v-for="(item, index) in finRepayBankAccountInfoDTOs" :key="index">
       <base-form
      :componentList="settlementAccount(index)"
      :formAttrs="{
        model: item,
        labelWidth: '140px',
        disabled: isDetail
      }"
      :showBtns="false"
      class="formData"
      ref="settlementAccountForm"
    >
    </base-form>
    </div>
    <div v-show="true">
      <title-center name='保理服务信息' :titleTips='titleTipsFactoringRelatedForm()'></title-center>
      <base-form
        :componentList="factoringRelatedForm"
        :formAttrs="{
          model: newServiceData,
          labelWidth: '140px',
          disabled: isDetail
        }"
        :showBtns="false"
        class="formData"
        ref="factoringForm"
      >
      </base-form>
    </div>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import TitleCenter from '../../components/title-center.vue'
import { supervise, factoringRelated, settlementAccount } from '../utils/config'
export default {
  components: { TitleCenter, BaseForm },
  name: 'accountAndService',
  props: {
    finRepayBankAccountInfoDTOs: Array, //
    dictType: String, // 产品类型
    accountData: Object,
    serviceData: Object,
    gysId: String, // 供应商Id
    formData: Object,
    schemeInfoList: Array,
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  watch: {

  },
  computed: {
    // settlementAccount () {
    //   return settlementAccount(this)
    // },
    settlementAccount () {
      return function (index) {
        return settlementAccount(this, index)
      }
    },
    superviseForm () {
      return supervise(this)
    },
    factoringRelatedForm () {
      return factoringRelated(this)
    },
    // 格式化数据
    newServiceData: {
      get () {
        // data.capitalCost = parseFloat(data.capitalCost).toLocaleString('en-US', { minimumFractionDigits: 2 })
        // data.serviceRate = parseFloat(data.serviceRate).toLocaleString('en-US', { minimumFractionDigits: 2 })
        // data.yearRate = parseFloat(data.yearRate).toLocaleString('en-US', { minimumFractionDigits: 2 })
        return this.serviceData
      },
      set (val) {
        this.$emit('update:serviceData', val)
      }
    }
  },
  methods: {
    // 监管账户提示 》》》》》 不清楚是否区分
    titleTipsSupervise () {
      if (this.dictType === '4') {
        return ''
      } else if (this.dictType === '3') {
        return '项目公司出具【应收账款债权转让通知书收讫函】'
      } else if (this.dictType === '1') {
        if (this.formData.financeType !== 'ONCE_MODEL') {
          return '项目公司出具【应收账款债权转让通知书收讫函】'
        }
      } else {
        return ''
      }
    },
    // 保理服务信息提示》》》》》 不清楚是否区分
    titleTipsFactoringRelatedForm () {
      if (this.dictType === '4') {
        return ''
      } else if (this.dictType === '3') {
        return '签署【应收账款转让协议】'
      } else if (this.dictType === '1') {
        if (this.formData.financeType !== 'ONCE_MODEL') {
          return '项目公司出具【应收账款债权转让通知书收讫函】'
        }
      } else {
        return ''
      }
    },
    clear () {
      this.$nextTick(() => {
        // if (this.$refs.settlementAccountForm) {
        // this.$refs.settlementAccountForm.clearValidate()
        // }
        if (this.$refs.superviseForm) {
          this.$refs.superviseForm.clearValidate()
        }
      })
    },
    clearFromValidate () {
      this.$refs.superviseForm.clearValidate()

      this.$nextTick(() => {
        if (this.$refs.settlementAccountForm) {
          this.$refs.settlementAccountForm.forEach(ele => {
            ele.clearValidate()
          })
        }
      })
      this.$refs.factoringForm.clearValidate()
    },
    addSelectAccount (data) {
      this.$refs.superviseForm.clearValidate()
      this.$emit('addSelectAccount', data)
    },
    add (data, index) {
      // console.log(data, 'changeFinRepayBankAccountInfoDTO')
      this.$refs.settlementAccountForm.forEach(ele => {
        ele.clearValidate()
      })
      this.$emit('changeFinRepayBankAccountInfoDTO', data, index)
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
